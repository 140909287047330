import React, { Component } from "react";
import "./App.css";
import "./index.css";
import axios from "axios";
import Charts from "./components/Charts";
import { Badge, Callout, Card, Metric } from "@tremor/react";
import { StatusOnlineIcon, UserCircleIcon } from "@heroicons/react/outline";
import ReactGA from "react-ga";

const apiUrl =
  "https://4w2rxq1r31.execute-api.us-east-2.amazonaws.com/prod/cbeth-data";

ReactGA.initialize("G-8Q8F3WQVQJ");

class App extends Component {
  state = {
    data: null,
    loading: false,
    ethUSDPrice: null,
    cbETHUSDPrice: null,
    cbETHETHPrice: null,
    circSupplyL7DAvg: null,
    circSupplyPrecedingL7DAvg: null,
    marketCapL7DAvg: null,
    marketCapPrecedingL7DAvg: null,
    apyL7DAvg: null,
    apyPrecedingL7DAvg: null,
    conversionRateL7DAvg: null,
    conversionRatePrecedingL7DAvg: null,
    deltaToFairValueL7DAvg: null,
    deltaToFairValuePrecedingL7DAvg: null,
    currentData: null,
  };

  getDateFromString = (dateString) => {
    const [month, day, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  componentDidMount() {
    this.setState({ loading: true });

    let max = 35;
    let interval = 3500;

    try {
      this.connect(max, interval);
    } catch (err) {
      console.log(err);
    }

    axios
      .get(apiUrl)
      .then((res) => {
        let { body: data } = res.data;

        let numResults = data.length;
        let comparisonNum = 7;
        let cutoff = numResults - comparisonNum;
        let preceding = cutoff - comparisonNum;
        const trailingElements = data.slice(comparisonNum * -1);
        const precedingElements = data.slice(preceding, cutoff);

        // Trailing L7D
        let circSupplyL7DAvg =
          trailingElements
            .map((obj) => Number(obj.circulating_supply))
            .reduce((acc, val) => acc + val, 0) / numResults;

        let marketCapL7DAvg =
          trailingElements
            .map((obj) => Number(obj.marketcap_usd))
            .reduce((acc, val) => acc + val, 0) / numResults;

        let totalSupplyL7DAvg =
          trailingElements
            .map((obj) => Number(obj.total_supply))
            .reduce((acc, val) => acc + val, 0) / numResults;

        let apyL7DAvg =
          trailingElements
            .map((obj) => Number(obj.apy))
            .reduce((acc, val) => acc + val, 0) / numResults;

        let conversionRateL7DAvg =
          trailingElements
            .map((obj) => Number(obj.conversion_rate))
            .reduce((acc, val) => acc + val, 0) / numResults;

        let deltaL7DAvg =
          trailingElements
            .map((obj) => Number(obj.delta_to_fv))
            .reduce((acc, val) => acc + val, 0) / numResults;

        this.setState(
          {
            circSupplyL7DAvg,
            marketCapL7DAvg,
            totalSupplyL7DAvg,
            apyL7DAvg,
            conversionRateL7DAvg,
            deltaL7DAvg,
          },
          () => {
            // Preceding L7D

            let circSupplyPrecedingL7DAvg =
              precedingElements
                .map((obj) => Number(obj.circulating_supply))
                .reduce((acc, val) => acc + val, 0) / numResults;

            let marketCapPrecedingL7DAvg =
              precedingElements
                .map((obj) => Number(obj.marketcap_usd))
                .reduce((acc, val) => acc + val, 0) / numResults;

            let totalSupplyPrecedingL7DAvg =
              precedingElements
                .map((obj) => Number(obj.total_supply))
                .reduce((acc, val) => acc + val, 0) / numResults;

            let apyPrecedingL7DAvg =
              precedingElements
                .map((obj) => Number(obj.apy))
                .reduce((acc, val) => acc + val, 0) / numResults;

            let conversionRatePrecedingL7DAvg =
              precedingElements
                .map((obj) => Number(obj.conversion_rate))
                .reduce((acc, val) => acc + val, 0) / numResults;

            let deltaPrecedingL7DAvg =
              precedingElements
                .map((obj) => Number(obj.delta_to_fv))
                .reduce((acc, val) => acc + val, 0) / numResults;

            this.setState(
              {
                circSupplyPrecedingL7DAvg,
                marketCapPrecedingL7DAvg,
                totalSupplyPrecedingL7DAvg,
                apyPrecedingL7DAvg,
                conversionRatePrecedingL7DAvg,
                deltaPrecedingL7DAvg,
              },
              () => {
                axios
                  .get("https://api.exchange.coinbase.com/wrapped-assets/CBETH")
                  .then((res) => {
                    let { data: current } = res;
                    this.setState({ current }, () => {
                      if (this.state.data == null) {
                        data = data.sort((a, b) => {
                          const dateA = this.getDateFromString(a.date);
                          const dateB = this.getDateFromString(b.date);
                          return dateA - dateB;
                        });
                        this.setState({ data });
                      }
                    });
                  })
                  .catch((e) => console.log(e));
              }
            );
          }
        );
      })
      .catch((err) => console.log(err))
      .finally(() => this.setState({ loading: false }));
  }

  connect = (max, interval) => {
    let counter = 0;
    const ws = new WebSocket("wss://ws-feed.exchange.coinbase.com");

    let apiCall = {
      type: "subscribe",
      channels: [
        { name: "ticker", product_ids: ["ETH-USD", "CBETH-USD", "CBETH-ETH"] },
      ],
    };

    ws.onopen = (event) => {
      ws.send(JSON.stringify(apiCall));
    };

    ws.onmessage = async (event) => {
      const json = JSON.parse(event.data);

      try {
        counter++;

        if (counter === max) {
          counter = 0;
          ws.close();
          setTimeout(() => this.connect(max, interval), interval);
        }

        if (json.product_id === "ETH-USD" && json.price) {
          this.setState({ ethUSDPrice: parseFloat(json.price) });
        } else if (json.product_id === "CBETH-USD" && json.price) {
          this.setState({ cbETHUSDPrice: parseFloat(json.price) });
        } else if (json.product_id === "CBETH-ETH" && json.price) {
          this.setState({ cbETHETHPrice: parseFloat(json.price) });
        }
      } catch (err) {
        console.log(err);
      }
    };

    ws.onclose = async () => {
      ws.close();
    };

    ws.onerror = async (event) => {
      console.log(event);
    };
  };

  render() {
    let {
      data,
      ethUSDPrice,
      cbETHUSDPrice,
      cbETHETHPrice,
      circSupplyL7DAvg,
      circSupplyPrecedingL7DAvg,
      marketCapL7DAvg,
      marketCapPrecedingL7DAvg,
      totalSupplyL7DAvg,
      totalSupplyPrecedingL7DAvg,
      apyL7DAvg,
      apyPrecedingL7DAvg,
      conversionRateL7DAvg,
      conversionRatePrecedingL7DAvg,
      deltaL7DAvg,
      deltaPrecedingL7DAvg,
      current,
    } = this.state;
    return (
      <div className="container max-w-screen-xl m-auto mt-12 mb-12">
        <div className="flex justify-center">
          <div className="sm:w-11/12 w-4/5">
            <Card className="mb-4">
              <Metric className="text-black">cbETH Over Time</Metric>
              <Callout className="mt-4" title="About">
                Data sourced from{" "}
                <a
                  className="font-semibold"
                  href="https://www.coingecko.com/api/documentation"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  CoinGecko
                </a>{" "}
                (pre Nov-25-2023) and{" "}
                <a
                  className="font-semibold"
                  href="https://docs.cloud.coinbase.com/exchange/reference/exchangerestapi_getwrappedasset"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Coinbase Exchange
                </a>{" "}
                (from Nov-25-2023 onwards). Changes reflect trailing last-7-day
                (L7D) average vs. preceding L7D average.
              </Callout>
              <div className="flex flex-wrap align-middle items-center">
                <a
                  href="https://twitter.com/@0xRishi"
                  className="w-fit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Badge
                    color="indigo"
                    className="hover:cursor-pointer mt-4 mr-2"
                    icon={UserCircleIcon}
                  >
                    Created by @0xRishi ↗
                  </Badge>
                </a>
                {ethUSDPrice && cbETHUSDPrice && cbETHETHPrice ? (
                  <>
                    <a
                      href="https://exchange.coinbase.com/trade/ETH-USD"
                      className="w-fit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Badge
                        color="red"
                        className="hover:cursor-pointer mr-2 mt-4"
                        icon={StatusOnlineIcon}
                      >
                        $
                        {Intl.NumberFormat("us")
                          .format(parseFloat(ethUSDPrice).toFixed(2))
                          .toString()}{" "}
                        ETH/USD ↗
                      </Badge>
                    </a>
                    <a
                      href="https://exchange.coinbase.com/trade/CBETH-USD"
                      className="w-fit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Badge
                        color="fuchsia"
                        className="hover:cursor-pointer mr-2 mt-4"
                        icon={StatusOnlineIcon}
                      >
                        $
                        {Intl.NumberFormat("us")
                          .format(parseFloat(cbETHUSDPrice).toFixed(2))
                          .toString()}{" "}
                        cbETH/USD ↗
                      </Badge>
                    </a>
                    <a
                      href="https://exchange.coinbase.com/trade/CBETH-ETH"
                      className="w-fit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Badge
                        color="rose"
                        className="hover:cursor-pointer mr-2 mt-4"
                        icon={StatusOnlineIcon}
                      >
                        {cbETHETHPrice} cbETH/ETH ↗
                      </Badge>
                    </a>
                  </>
                ) : null}
              </div>
            </Card>
            {data ? (
              <Charts
                data={data}
                circSupplyDelta={
                  circSupplyL7DAvg / circSupplyPrecedingL7DAvg - 1
                }
                marketCapDelta={marketCapL7DAvg / marketCapPrecedingL7DAvg - 1}
                totalSupplyDelta={
                  totalSupplyL7DAvg / totalSupplyPrecedingL7DAvg - 1
                }
                apyDelta={apyL7DAvg / apyPrecedingL7DAvg - 1}
                conversionRateDelta={
                  conversionRateL7DAvg / conversionRatePrecedingL7DAvg - 1
                }
                deltaDelta={deltaL7DAvg / deltaPrecedingL7DAvg - 1}
                cbETHETHPrice={cbETHETHPrice}
                cbETHUSDPrice={cbETHUSDPrice}
                current={current}
              />
            ) : (
              <div className="loader loader-dark"></div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
