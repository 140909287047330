import React, { Component } from "react";
import "../index.css";

import {
  Card,
  Metric,
  Text,
  AreaChart,
  BadgeDelta,
  Flex,
  Grid,
  Badge,
} from "@tremor/react";
import { StatusOnlineIcon } from "@heroicons/react/outline";

const valueFormatter = (number) =>
  `${
    number > 1000
      ? Intl.NumberFormat("us").format(number).toString()
      : number < 1
      ? (number * 100).toFixed(2).toString() + "%"
      : number
  }`;

class Charts extends Component {
  reformatNum = (num, format) => {
    if (format === "currency") {
      return `$${Intl.NumberFormat("us")
        .format(parseFloat(num).toFixed(0))
        .toString()}`;
    } else if (format === "bignum") {
      if (num >= 1000000000) {
        return `$${(num / 1000000000).toFixed(1)}B`;
      } else if (num >= 1000000) {
        return `$${(num / 1000000).toFixed(1)}M`;
      } else if (num >= 1000) {
        return `$${(num / 1000).toFixed(1)}K`;
      } else {
        return `$${num.toFixed(2)}`;
      }
    } else if (format === "percent") {
      return `${(parseFloat(num) * 100).toFixed(4).toString()}%`;
    } else if (format === "raw") {
      return parseFloat(num).toFixed(6).toString();
    } else {
      return Intl.NumberFormat("us")
        .format(parseFloat(num).toFixed(0))
        .toString();
    }
  };

  render() {
    let {
      data,
      circSupplyDelta,
      marketCapDelta,
      totalSupplyDelta,
      apyDelta,
      conversionRateDelta,
      deltaDelta,
      current,
      cbETHETHPrice,
      cbETHUSDPrice,
    } = this.props;

    let categories = [
      {
        title: "circulating_supply",
        metricType: "standard",
        delta: (circSupplyDelta * 100).toFixed(2).toString() + "%",
        deltaType:
          circSupplyDelta >= 0 ? "moderateIncrease" : "moderateDecrease",
      },
      {
        title: "marketcap_usd",
        metricType: "currency",
        delta: (marketCapDelta * 100).toFixed(2).toString() + "%",
        deltaType:
          marketCapDelta >= 0 ? "moderateIncrease" : "moderateDecrease",
      },
      {
        title: "total_supply",
        metricType: "standard",
        delta: (totalSupplyDelta * 100).toFixed(2).toString() + "%",
        deltaType:
          totalSupplyDelta >= 0 ? "moderateIncrease" : "moderateDecrease",
      },
      {
        title: "apy",
        metricType: "percent",
        delta: (apyDelta * 100).toFixed(2).toString() + "%",
        deltaType: apyDelta >= 0 ? "moderateIncrease" : "moderateDecrease",
      },
      {
        title: "conversion_rate",
        metricType: "raw",
        delta: (conversionRateDelta * 100).toFixed(2).toString() + "%",
        deltaType:
          conversionRateDelta >= 0 ? "moderateIncrease" : "moderateDecrease",
      },
      {
        title: "delta_to_fv",
        metricType: "percent",
        delta: (deltaDelta * 100).toFixed(2).toString() + "%",
        deltaType: deltaDelta >= 0 ? "moderateIncrease" : "moderateDecrease",
      },
    ];

    return (
      <Grid numItemsSm={1} numItemsLg={2} className="gap-4">
        {categories.map((item) => (
          <Card key={item.title}>
            <Flex alignItems="start">
              <Text>{item.title}</Text>
              {item.title !== "delta_to_fv" &&
              item.title !== "conversion_rate" ? (
                <BadgeDelta deltaType={item.deltaType}>{item.delta}</BadgeDelta>
              ) : null}
            </Flex>
            <Flex
              className="space-x-3"
              justifyContent="start"
              alignItems="baseline"
            >
              <Metric className="text-black ">
                {item.title === "delta_to_fv"
                  ? (
                      (parseFloat(cbETHETHPrice) /
                        parseFloat(current.conversion_rate) -
                        1) *
                      100
                    )
                      .toFixed(4)
                      .toString() + "%"
                  : item.title === "marketcap_usd"
                  ? this.reformatNum(
                      parseFloat(cbETHUSDPrice) *
                        parseFloat(current.circulating_supply),
                      "bignum"
                    )
                  : this.reformatNum(current[item.title], item.metricType)}
              </Metric>
              <Badge color="indigo" icon={StatusOnlineIcon}>
                Current
              </Badge>
            </Flex>
            <AreaChart
              className="mt-6 h-40"
              data={
                ["marketcap_usd", "circulating_supply"].includes(item.title)
                  ? data
                  : data.filter((d) => d.apy)
              }
              index="date"
              valueFormatter={valueFormatter}
              categories={[item.title]}
              colors={["indigo"]}
              showXAxis={true}
              showGridLines={false}
              startEndOnly={true}
              showYAxis={false}
              showLegend={false}
              showAnimation={true}
              autoMinValue={true}
              curveType="monotone"
            />
          </Card>
        ))}
      </Grid>
    );
  }
}

export default Charts;
